import API_URL from './config';
import { fetchWithAuth } from './utils';

export async function downloadExport(
    type: 'accounting' | 'royalties',
    orderIds: number[],
    separator: string,
    channel: string
): Promise<Blob> {
    const response = await fetchWithAuth(`${API_URL}/export/${type}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ orderIds, separator, channel }),
    });

    if (!response.ok) {
        throw new Error('Erreur lors du téléchargement de l\'export');
    }

    return await response.blob();
} 
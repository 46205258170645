import IdTransformer from 'utils/idTransformer';
import { Order } from '../types/order';
import API_URL from './config';
import { fetchWithAuth } from './utils';

interface OrderResponse {
    member: Order[];
    totalItems: number;
}

const handleError = async (response: Response): Promise<never> => {
    const errorText = await response.text();
    throw new Error(`${response.status}: ${errorText}`);
};

const getOrdersCacheKey = () => `orders`;

export const orderService = {
    fetchOrders: async (
        page: number = 1,
        itemsPerPage: number = 20,
        search?: string,
        status?: string
    ): Promise<OrderResponse> => {
        try {
            let url = `${API_URL}/orders?page=${page}&itemsPerPage=${itemsPerPage}`;
            
            if (search) {
                url += `&search=${encodeURIComponent(search)}`;
            }
            if (status) {
                url += `&status=${encodeURIComponent(status)}`;
            }
            
            const response = await fetchWithAuth(url);
            
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            
            const data: OrderResponse = await response.json();
            data.member = IdTransformer.addIdInMemberList(data.member) as Order[];
            
            return data;
        } catch (error) {
            console.error('Erreur lors de la récupération des commandes:', error);
            throw error;
        }
    },

    fetchOrderById: async (orderId: string): Promise<Order> => {
        try {
            const response = await fetchWithAuth(`${API_URL}/orders/${orderId}?expand=customOptions`);
            
            if (!response.ok) {
                throw new Error(`Erreur HTTP: ${response.status}`);
            }
            
            const order = await response.json();
    
            // Traitement pour extraire les options de personnalisation
            if (order.orderItems) {
                order.orderItems.forEach((item: any) => {
                    if (item.product_option?.extension_attributes?.custom_options) {
                        item.customOptions = item.product_option.extension_attributes.custom_options;
                    }
                });
            }
    
            return order;
        } catch (error) {
            console.error('Erreur lors de la récupération des détails de la commande:', error);
            throw error;
        }
    },

    updateOrder: async (orderId: string, modifiedOrderData: Partial<Order>): Promise<Order> => {
        const response = await fetchWithAuth(`${API_URL}/orders/${orderId}`, {
            method: 'PATCH',
            body: JSON.stringify(modifiedOrderData)
        });

        if (!response.ok) {
            await handleError(response);
        }

        return response.json(); 
    },  

    createDuplicateOrder: async (modifiedOrderData: Partial<Order>): Promise<Order> => {
        const order = {
            ...modifiedOrderData,
            id: undefined,
            '@id': undefined
        };
        const response = await fetchWithAuth(`${API_URL}/orders`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/ld+json'

            },
            body: JSON.stringify(order)
        });
    
        if (!response.ok) {
            const error = await response.json();
            console.error('Erreur lors de la création de la commande:', error);
            throw new Error(`Erreur lors de la création: ${error.message}`);
        }
    
        const newOrder = await response.json();
        localStorage.removeItem(getOrdersCacheKey());
    
        return newOrder;
    },

    deleteOrder: async (orderId: string): Promise<boolean> => {
        const id = orderId.split('/').pop(); 
        const url = `${API_URL}/orders/${id}`; 
    
        const response = await fetchWithAuth(url, {
            method: 'DELETE'
        });
    
        if (!response.ok) {
            await handleError(response);
        }
    
        return true;
    }
}; 
import React from 'react';

export const highlightText = (text: string, searchTerm: string) => {
  if (!searchTerm || !text) return text;
  
  const parts = text.toString().split(new RegExp(`(${searchTerm})`, 'gi'));
  
  return (
    <span>
      {parts.map((part, index) => 
        part.toLowerCase() === searchTerm.toLowerCase() ? (
          <span key={index} className="bg-yellow-200">{part}</span>
        ) : (
          part
        )
      )}
    </span>
  );
}; 
import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Product } from '../types/product';
import { productService } from '../api/productService';
import { FaSpinner, FaArrowLeft } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { find } from 'lodash';

interface ProductDetailProps {
  onClose?: () => void;
}

const ProductDetail: React.FC<ProductDetailProps> = ({ onClose }) => {
  const { productId } = useParams<{ productId: string }>();
  const [product, setProduct] = useState<Product | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [attributeOptions, setAttributeOptions] = useState<any[]>([]);
  const [picking, setPicking] = useState<string>('');

  useEffect(() => {
    const fetchProductDetails = async () => {
      if (!productId) return;
      try {
        setLoading(true);
        const fetchedProduct = await productService.fetchProductById(productId);
        setProduct(fetchedProduct);
        setPicking(fetchedProduct.picking || '');

        const fetchedAttributeOptions = await productService.fetchAttributeOptions();
        setAttributeOptions(fetchedAttributeOptions.member);
      } catch (err) {
        setError('Erreur lors du chargement des détails du produit');
      } finally {
        setLoading(false);
      }
    };

    fetchProductDetails();
  }, [productId]);

  const findAttributeValue = (attributeCode: string) => {
    if (!product?.customAttributes) return null;
    console.log('attributeCode', attributeCode);
    const attribute = find(product.customAttributes, { attribute_code: attributeCode });
    if (!attribute) return null;

    const option = attributeOptions.find(opt => opt.value === attribute.value);
    return option ? option.label : attribute.label;
  };

  const saveChanges = async () => {
    if (!product || !productId) return;

    try {
      const updates = {
        picking,
      };
      await productService.updateProduct(productId, updates);
      toast.success('Produit mis à jour avec succès!');
      if (onClose) onClose();
    } catch (err) {
      setError('Erreur lors de la sauvegarde des modifications');
      toast.error('Erreur lors de la sauvegarde des modifications');
    }
  };

  if (loading) return (
    <div className="flex flex-col justify-center items-center h-screen">
      <FaSpinner className="animate-spin h-12 w-12 text-blue-600 mb-4" />
      <p className="text-gray-600 font-medium">Chargement des données...</p>
    </div>
  );
  
  if (error) return (
    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
      {error}
    </div>
  );
  
  if (!product) return (
    <div className="bg-yellow-100 border border-yellow-400 text-yellow-700 px-4 py-3 rounded relative">
      Produit non trouvé
    </div>
  );
  console.log('product', product);

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-4xl font-bold mb-6" style={{ color: '#06748c' }}>{product?.name}</h1>
      <Link to="/produits" className="flex items-center text-blue-500 hover:text-blue-700 font-semibold mb-8">
        <FaArrowLeft className="mr-2" />Retourner à la liste des produits
      </Link>

      <div className="bg-white shadow rounded-lg p-6 space-y-6">
        {/* Bloc Details */}
        <div className="bg-gray-50 rounded-lg p-6">
          <h3 className="text-lg font-medium text-gray-900 mb-4 flex items-center">
            <span className="mr-2">📋</span>
            Détails
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {[
              { label: 'Nom', value: product.name },
              { label: 'Marque', value: findAttributeValue('brand') },
              { label: 'SKU', value: product.sku },
              { label: 'ASIN', value: findAttributeValue('asin') },
              { label: 'Prix', value: `${product.price} €` },
            ].map((item, index) => (
              <div key={index} className="bg-white p-4 rounded-md shadow-sm">
                <div className="text-sm text-gray-500 mb-1">{item.label}</div>
                <div className="font-medium text-gray-900">{item.value || '-'}</div>
              </div>
            ))}
          </div>
        </div>

        {/* Bloc Données universelles */}
        <div className="bg-gray-50 rounded-lg p-6">
          <h3 className="text-lg font-medium text-gray-900 mb-4 flex items-center">
            <span className="mr-2">🌍</span>
            Données universelles
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {[
              { label: 'UUID', code: 'universal_unique_product_id' },
              { label: 'Groupe', code: 'universal_product_group' },
              { label: 'Type', code: 'universal_product_type' },
              { label: 'Licence', code: 'universal_product_licence' },
              { label: 'Thème', code: 'universal_product_theme' },
              { label: 'Variation', code: 'universal_product_variation' },
              { label: 'Forme', code: 'universal_product_shape' },
              { label: 'Taille', code: 'universal_product_size' },
              { label: 'Couleur', code: 'universal_product_color' },
              { label: 'Langue', code: 'universal_product_lang' },
            ].map((item, index) => (
              <div key={index} className="bg-white p-4 rounded-md shadow-sm">
                <div className="text-sm text-gray-500 mb-1">{item.label}</div>
                <div className="font-medium text-gray-900">{findAttributeValue(item.code) || '-'}</div>
              </div>
            ))}
          </div>
        </div>

        {/* Bloc Labelmaker */}
        <div className="bg-gray-50 rounded-lg p-6">
          <h3 className="text-lg font-medium text-gray-900 mb-4 flex items-center">
            <span className="mr-2">🏷️</span>
            Labelmaker
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {[
              { label: 'Profil', code: 'labelmaker_profil_id' },
              { label: 'Profil de personnalisation', code: 'labelmaker_profil_personalization_id' },
              { label: 'Support', code: 'labelmaker_support' },
              { label: 'Forme', code: 'labelmaker_label_shape' },
              { label: 'Taille', code: 'labelmaker_label_size' },
              { label: 'Quantité', code: 'labelmaker_label_qty' },
              { 
                label: 'Plaquette extra', 
                code: 'labelmaker_is_extra',
                format: (value: any) => value ? 'Oui' : 'Non'
              },
            ].map((item, index) => (
              <div key={index} className="bg-white p-4 rounded-md shadow-sm">
                <div className="text-sm text-gray-500 mb-1">{item.label}</div>
                <div className="font-medium text-gray-900">
                  {item.format 
                    ? item.format(findAttributeValue(item.code))
                    : findAttributeValue(item.code) || '-'}
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Bloc Logistique */}
        <div className="bg-gray-50 rounded-lg p-6">
          <h3 className="text-lg font-medium text-gray-900 mb-4 flex items-center">
            <span className="mr-2">📦</span>
            Logistique
          </h3>
          <div>
            <label htmlFor="picking_code" className="block text-sm font-medium text-gray-700">
              Code picking
            </label>
            <textarea
              id="picking_code"
              value={picking}
              onChange={(e) => setPicking(e.target.value)}
              rows={4}
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>
        </div>

        {/* Boutons de contrôle */}
        <div className="flex justify-end space-x-3">
          <button
            onClick={onClose}
            className="py-2 px-4 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Annuler
          </button>
          <button
            onClick={saveChanges}
            className="py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Enregistrer
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductDetail; 
import API_URL from './config';
import { fetchWithAuth } from './utils';
import { toast } from 'react-toastify';

export const downloadService = {
  async downloadFile(endpoint: string, defaultFileName: string, fileType: string): Promise<void> {
    try {
      const response = await fetchWithAuth(`${API_URL}${endpoint}`);

      if (!response.ok) {
        throw new Error(`HTTP error: ${response.status}`);
      }

      const contentDisposition = response.headers.get('content-disposition');
      let fileName = defaultFileName;
      
      if (contentDisposition) {
        const matches = contentDisposition.match(/filename="([^"]+)"/);
        if (matches && matches[1]) {
          fileName = matches[1];
        }
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
      toast.success(`Fichier ${fileType.toUpperCase()} téléchargé avec succès!`);
    } catch (error) {
      console.error(`Erreur lors du téléchargement du ${fileType.toUpperCase()}:`, error);
      toast.error(`Erreur lors du téléchargement du fichier ${fileType.toUpperCase()}`);
      throw error;
    }
  }
};

import IdTransformer from 'utils/idTransformer';
import { User } from '../types/user';
import API_URL from './config';
import { getAuthHeaders, fetchWithAuth } from './utils';

interface UserResponse {
    member: User[];
}

export const userService = {
    getAllUsers: async (): Promise<UserResponse> => {
        const response = await fetchWithAuth(`${API_URL}/users`);
        
        if (!response.ok) {
            throw new Error('Failed to fetch users');
        }

        const builtResponse = await response.json();
        builtResponse.member = IdTransformer.addIdInMemberList(builtResponse.member);
        return builtResponse;
    },

    createUser: async (userData: Partial<User>): Promise<User> => {
        const response = await fetch(`${API_URL}/users`, {
            method: 'POST',
            headers: {
                ...getAuthHeaders(),
                'Content-Type': 'application/ld+json',
                'Accept': 'application/ld+json',
            },
            body: JSON.stringify(userData),
        });

        if (!response.ok) {
            throw new Error('Failed to create user');
        }

        const user = await response.json();
        return {
            ...user,
            id: IdTransformer.getNumericId(user),
        };
    },

    patchUser: async (userId: number, userData: Partial<User>): Promise<User> => {
        const response = await fetch(`${API_URL}/users/${userId}`, {
            method: 'PATCH',
            headers: {
                ...getAuthHeaders(),
                'Content-Type': 'application/merge-patch+json',
            },
            body: JSON.stringify(userData),
        });

        if (!response.ok) {
            throw new Error('Failed to update user');
        }
        
        const user = await response.json();
        return {
            ...user,
            id: IdTransformer.getNumericId(user),
        };
    },

    deleteUser: async (userId: number): Promise<void> => {
        const response = await fetch(`${API_URL}/users/${userId}`, {
            method: 'DELETE',
            headers: getAuthHeaders(),
        });

        if (!response.ok) {
            throw new Error('Failed to delete user');
        }
    },
}; 
import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons';
import { FaSpinner } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { authenticate } from '../api/auth';
import { AuthResponse } from '../types/user';
import Logo from '../assets/logo-ludihub_2024.png';

interface LoginProps {
  onLoginSuccess: (data: AuthResponse) => void;
}

const Login: React.FC<LoginProps> = ({ onLoginSuccess }) => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const containerVariants = {
    hidden: { opacity: 0, x: '-100vw', y: 0, rotate: 0 },
    visible: {
      opacity: 1, x: 0, y: 0, rotate: 0,
      transition: {
        type: 'spring',
        stiffness: 120,
        damping: 20,
        when: "beforeChildren",
        staggerChildren: 0.3
      }
    },
    exit: {
      x: '100vw', y: 0, rotate: 0,
      transition: { ease: 'easeInOut' }
    }
  };

  const childVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { duration: 0.5 }
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!email || !password) {
      setError('Veuillez remplir tous les champs.');
      return;
    }
    setIsLoading(true);
    try {
      const userData = await authenticate(email, password);
      onLoginSuccess(userData);
      navigate('/accueil');
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Une erreur est survenue');
      setIsLoading(false);
    }
  };

  return (
    <div className="flex justify-center items-center h-screen">
      <AnimatePresence>
        {!isLoading ? (
          <motion.div 
            className="bg-white rounded-lg shadow-lg p-6" 
            style={{ width: '400px' }}
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            <div className="mb-4 text-center">
              <img src={Logo} alt="Logo Ludilabel" className="mx-auto mb-4" />
              <h3 className="text-2xl font-semibold">Se Connecter</h3>
            </div>
            {error && (
              <div className="bg-pink-100 border border-pink-400 text-pink-700 px-4 py-3 rounded relative" role="alert">
                {error}
              </div>
            )}
            <form onSubmit={handleSubmit}>
              <motion.div variants={childVariants}>
                {/* Email input */}
                <div className="mb-4">
                  <div className="flex items-center mb-2">
                    <FontAwesomeIcon icon={faEnvelope} style={{ color: '#53c6b6' }} />
                    <label htmlFor="email" className="ml-2 text-gray-700 text-sm font-bold">Email</label>
                  </div>
                  <input
                    type="email"
                    className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Entrez votre email"
                    autoComplete="username"
                  />
                </div>
                {/* Password input */}
                <div className="mb-4">
                  <div className="flex items-center mb-2">
                    <FontAwesomeIcon icon={faLock} style={{ color: '#ff9667' }} />
                    <label htmlFor="password" className="ml-2 text-gray-700 text-sm font-bold">Mot de passe</label>
                  </div>
                  <input
                    type="password"
                    className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Entrez votre mot de passe"
                    autoComplete="current-password"
                  />
                </div>
                <div className="mb-4">
                  <button
                    type="submit"
                    style={{ backgroundColor: '#272860' }}
                    className="w-full hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  >
                    Connexion
                  </button>
                </div>
              </motion.div>
            </form>
          </motion.div>
        ) : (
          <div className="flex justify-center items-center">
            <FaSpinner className="animate-spin h-8 w-8 mr-3" />
            Chargement...
          </div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Login; 
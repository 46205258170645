import React from 'react';
import Select, { StylesConfig } from 'react-select';

export interface SelectOption {
  value: string;
  label: string;
}

interface CustomSelectProps {
  options: SelectOption[];
  value?: SelectOption;
  onChange: (value: string) => void;
  placeholder?: string;
  className?: string;
  isClearable?: boolean;
  isSearchable?: boolean;
  isDisabled?: boolean;
}

export const customStyles: StylesConfig<SelectOption> = {
  control: (provided, state) => ({
    ...provided,
    borderColor: state.isFocused ? '#272860' : '#E5E7EB',
    boxShadow: state.isFocused ? '0 0 0 1px #272860' : 'none',
    '&:hover': {
      borderColor: '#272860'
    },
    minHeight: '42px'
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#272860' : state.isFocused ? '#F3F4F6' : 'white',
    color: state.isSelected ? 'white' : '#374151',
    cursor: 'pointer',
    '&:active': {
      backgroundColor: '#272860'
    }
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 9999
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 9999
  })
};

const CustomSelect: React.FC<CustomSelectProps> = ({
  options,
  value,
  onChange,
  placeholder,
  className = '',
  isClearable = true,
  isSearchable = true,
  isDisabled = false
}) => {
  return (
    <Select<SelectOption>
      options={options}
      value={value}
      onChange={(option) => onChange(option?.value || '')}
      styles={customStyles}
      placeholder={placeholder}
      className={`text-sm ${className}`}
      isClearable={isClearable}
      isSearchable={isSearchable}
      isDisabled={isDisabled}
    />
  );
};

export default CustomSelect; 
import React, { ChangeEvent, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { User } from '../types/user';
import { useForm } from '../hooks/useForm';
import LoadingSpinner from './LoadingSpinner';

interface UserFormProps {
    onSave: (user: User) => Promise<void>;
    user?: User | null;
    onClose?: () => void;
}

const UserForm: React.FC<UserFormProps> = ({ onSave, user, onClose }) => {
    const initialValues: Partial<User> = {
        email: '',
        roles: [],
        active: true,
        password: '',
        ...user
    };

    const { 
        values, 
        handleChange, 
        handleArrayChange 
    } = useForm<Partial<User>>(initialValues);
    
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsSubmitting(true);
        setError(null);

        try {
            await onSave(values as User);
            if (onClose) onClose();
        } catch (err) {
            setError(err instanceof Error ? err.message : 'Une erreur est survenue');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <AnimatePresence>
            {!isSubmitting ? (
                <motion.form onSubmit={handleSubmit} className="bg-white rounded">
                    <h2 className="text-2xl font-bold mb-6 text-gray-700">
                        {user ? 'Modifier l\'utilisateur' : 'Nouvel utilisateur'}
                    </h2>
                    
                    {/* Email field */}
                    <FormField
                        label="Email"
                        name="email"
                        type="email"
                        value={values.email}
                        onChange={handleChange}
                        required
                    />

                    {/* Password field */}
                    {!user && (
                        <FormField
                            label="Mot de passe"
                            name="password"
                            type="password"
                            value={values.password}
                            onChange={handleChange}
                            required
                        />
                    )}

                    {/* Roles field */}
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">
                            Rôle
                        </label>
                        <select
                            name="roles"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            value={values.roles?.[0] || 'ROLE_USER'}
                            onChange={(e) => handleArrayChange('roles', e.target.value, true)}
                        >
                            <option value="ROLE_USER">Utilisateur</option>
                            <option value="ROLE_ADMIN">Administrateur</option>
                        </select>
                    </div>

                    {/* Active status */}
                    <CheckboxField
                        label="Actif"
                        name="active"
                        checked={values.active}
                        onChange={handleChange}
                    />

                    {error && <ErrorMessage message={error} />}

                    <SubmitButton isSubmitting={isSubmitting} />
                </motion.form>
            ) : (
                <div className="flex justify-center items-center">
                    <LoadingSpinner />
                </div>
            )}
        </AnimatePresence>
    );
};

// Composants réutilisables
const FormField: React.FC<{
    label: string;
    name: string;
    type?: string;
    value?: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    required?: boolean;
}> = ({ label, ...props }) => (
    <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={props.name}>
            {label}
        </label>
        <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            {...props}
        />
    </div>
);

const CheckboxField: React.FC<{
    label: string;
    name: string;
    value?: string;
    checked?: boolean;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}> = ({ label, ...props }) => (
    <label className="flex items-center space-x-2">
        <input
            type="checkbox"
            className="form-checkbox h-4 w-4 text-06748c rounded border-gray-300 focus:ring-06748c"
            {...props}
        />
        <span className="text-gray-700">{label}</span>
    </label>
);

const ErrorMessage: React.FC<{ message: string }> = ({ message }) => (
    <div className="mb-4 text-red-500 text-sm">
        {message}
    </div>
);

const SubmitButton: React.FC<{ isSubmitting: boolean }> = ({ isSubmitting }) => (
    <div className="flex justify-end">
        <button
            type="submit"
            className="bg-06748c hover:bg-06748c-darker text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            disabled={isSubmitting}
        >
            {isSubmitting ? 'Enregistrement...' : 'Enregistrer'}
        </button>
    </div>
);

export default UserForm; 
import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { User } from '../types/user';
import { userService } from '../api/userService';
import { toast } from 'react-toastify';

interface UserEditFormProps {
  user: User;
  onSave: (user: User) => void;
  onCancel: () => void;
}

const UserEditForm: React.FC<UserEditFormProps> = ({ user, onSave, onCancel }) => {
  const [email, setEmail] = useState<string>(user.email);
  const [password, setPassword] = useState<string>('');
  const [isActive, setIsActive] = useState<boolean>(user.active);
  const [role, setRole] = useState<string>(user.roles[0] || 'ROLE_USER');
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [updateSuccess, setUpdateSuccess] = useState<boolean>(false);

  useEffect(() => {
    setEmail(user.email);
    setIsActive(user.active);
    setRole(user.roles[0] || 'ROLE_USER');
  }, [user]);

  const formVariants = {
    hidden: {
      opacity: 0,
      y: -20,
      transition: {
        duration: 0.5
      }
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5
      }
    },
    exit: {
      opacity: 0,
      y: 20,
      transition: {
        duration: 0.5
      }
    }
  };

  const spinnerVariants = {
    loading: { 
      rotate: 360, 
      transition: { 
        repeat: Infinity, 
        duration: 1 
      } 
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    setError(null);
    const userId = user.id;

    if (!userId) {
      setError("L'ID de l'utilisateur est indéfini.");
      toast.error("L'ID de l'utilisateur est indéfini.");
      setLoading(false);
      return;
    }

    const userData: Partial<User> = {
      email,
      roles: [role],
      active: isActive,
      ...(password && { password })
    };

    try {
      await userService.patchUser(userId, userData);
      onSave(userData as User);
      setUpdateSuccess(true);
      toast.success('Utilisateur mis à jour avec succès!');
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : "Erreur lors de la sauvegarde de l'utilisateur";
      setError(errorMessage);
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <AnimatePresence>
      {!updateSuccess && (
        <motion.form
          onSubmit={handleSubmit}
          variants={formVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          <h2 className="text-2xl font-bold mb-4 text-gray-800">Modifier l'utilisateur</h2>
          {error && <div className="mb-4 text-red-500">{error}</div>}
          <div className="mb-4">
            <label htmlFor="email" className="block text-gray-700 text-sm font-bold mb-2">
              Email
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="password" className="block text-gray-700 text-sm font-bold mb-2">
              Mot de passe
            </label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>

          <div className="mb-4 flex items-center">
            <input
              type="checkbox"
              id="isActive"
              checked={isActive}
              onChange={(e) => setIsActive(e.target.checked)}
              className="form-checkbox h-5 w-5"
              style={{ color: '#272860' }}
            />
            <label htmlFor="isActive" className="ml-2 text-gray-700 text-sm font-bold">
              Actif
            </label>
          </div>

          <div className="mb-4">
            <label htmlFor="role" className="block text-gray-700 text-sm font-bold mb-2">
              Rôle
            </label>
            <select
              id="role"
              value={role}
              onChange={(e) => setRole(e.target.value)}
              className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
            >
              <option value="ROLE_USER">Utilisateur</option>
              <option value="ROLE_ADMIN">Administrateur</option>
            </select>
          </div>

          <div className="flex justify-between">
            <button
              type="button"
              onClick={onCancel}
              className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Annuler
            </button>
            <button
              type="submit"
              className="bg-06748c hover:bg-06748c-darker text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              disabled={loading}
            >
              {loading ? (
                <motion.div className="spinner" variants={spinnerVariants} animate="loading" />
              ) : (
                'Enregistrer'
              )}
            </button>
          </div>
        </motion.form>
      )}
    </AnimatePresence>
  );
};

export default UserEditForm; 
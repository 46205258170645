import React, { useState, useEffect } from 'react';
import { userService } from '../api/userService';
import UserTable from '../components/UserTable';
import UserForm from '../components/UserForm';
import { User } from '../types/user';
import ReactModal from 'react-modal';
import UserEditForm from 'components/UserEditForm';

const UserPage: React.FC = () => {
    const [users, setUsers] = useState<User[]>([]);
    const [selectedUser, setSelectedUser] = useState<User | null>(null);
    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const [showForm, setShowForm] = useState<boolean>(false);

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        try {
            const response = await userService.getAllUsers();
            if (response.member && Array.isArray(response.member)) {
                setUsers(response.member);
            } else {
                throw new Error('Format de réponse inattendu');
            }
        } catch (error) {
            console.error('Fetch users failed:', error);
            setUsers([]);
        }
    };

    const handleSaveUser = async (user: User) => {
        try {
            if (isEditMode && selectedUser) {
                await userService.patchUser(selectedUser.id, user);
            } else {
                await userService.createUser(user);
            }
            fetchUsers();
            closeForm();
        } catch (error) {
            console.error('Save user failed:', error);
        }
    };

    const handleEditUser = (user: User) => {
        setSelectedUser(user);
        setIsEditMode(true);
        setShowForm(true);
    };

    const handleDeleteUser = async (userId: number) => {
        try {
            await userService.deleteUser(userId);
            fetchUsers();
        } catch (error) {
            console.error('Delete user failed:', error);
        }
    };

    const closeForm = () => {
        setSelectedUser(null);
        setIsEditMode(false);
        setShowForm(false);
    };

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4">Gestion des Utilisateurs</h1>
            <button
                onClick={() => setShowForm(true)}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4"
            >
                Ajouter un utilisateur
            </button>
            <UserTable
                users={users}
                onEditUser={handleEditUser}
                onDeleteUser={handleDeleteUser}
            />

            <ReactModal 
                isOpen={showForm}
                onRequestClose={closeForm}
                className="modal"
                overlayClassName="overlay"
            >
                {isEditMode && selectedUser ? (
                    <UserEditForm
                        user={selectedUser!}
                        onCancel={closeForm}
                        onSave={handleSaveUser}
                    />
                ) : (
                    <UserForm
                        onSave={handleSaveUser}
                    />
                )}
            </ReactModal>
        </div>
    );
};

export default UserPage; 
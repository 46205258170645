import React from 'react';

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
  className?: string;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  onPageChange,
  className = ''
}) => {
  return (
    <div className={`flex justify-center mt-4 space-x-2 ${className}`}>
      <button
        onClick={() => onPageChange(Math.max(currentPage - 1, 1))}
        disabled={currentPage === 1}
        className="bg-[#272860] hover:bg-[#1e1e50] text-white font-bold py-2 px-4 rounded disabled:opacity-50 transition duration-300 ease-in-out"
      >
        Précédent
      </button>
      
      <span className="flex items-center px-4 py-2 text-gray-700">
        Page {currentPage} sur {totalPages}
      </span>
      
      <button
        onClick={() => onPageChange(Math.min(currentPage + 1, totalPages))}
        disabled={currentPage === totalPages}
        className="bg-[#272860] hover:bg-[#1e1e50] text-white font-bold py-2 px-4 rounded disabled:opacity-50 transition duration-300 ease-in-out"
      >
        Suivant
      </button>
    </div>
  );
};

export default Pagination; 
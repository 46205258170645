import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { 
  FaHome, 
  FaBox, 
  FaShoppingBasket, 
  FaUsers, 
  FaSignOutAlt,
  FaBars,
  FaTimes 
} from 'react-icons/fa';
import Logo from '../assets/logo-ludihub_2024.png';

interface SidebarProps {
  onLogout: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ onLogout }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const location = useLocation();

  const colors = {
    home: '#4CAF50',
    users: '#FFC107',
    products: '#2196F3',
    orders: '#FF5722',
    logout: '#795548',
    toggle: 'text-gray-800',
  };

  const isActive = (path: string) => location.pathname === path ? 'text-white' : 'text-gray-600 hover:bg-gray-100';

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  const isUserAdmin = (): boolean => {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    return user && user.roles.includes('ROLE_ADMIN');
  };

  return (
    <>
      <button 
        className={`md:hidden fixed right-4 top-4 z-30 ${colors.toggle}`} 
        onClick={toggleSidebar}
      >
        {isSidebarOpen ? <FaTimes className="text-2xl" /> : <FaBars className="text-2xl" />}
      </button>
      <div className={`fixed inset-y-0 left-0 z-20 w-64 transform ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out bg-white shadow-lg md:relative md:translate-x-0`}>
        <div className="py-5 px-6">
          <div className="flex items-center justify-center">
            <img src={Logo} alt="Logo" className="h-14 w-auto" />
          </div>
          <nav className="mt-8">
            <Link 
              to="/accueil" 
              className={`flex items-center px-4 py-3 rounded-lg mb-2 ${isActive('/accueil')}`}
              style={{ 
                backgroundColor: location.pathname === '/accueil' ? '#272860' : 'transparent',
                color: location.pathname === '/accueil' ? 'white' : '',
                transition: 'background-color 0.3s ease'
              }}
            >
              <FaHome className="text-xl mr-3" style={{ color: location.pathname === '/accueil' ? 'currentColor' : colors.home }} />
              Tableau de Bord
            </Link>
            {isUserAdmin() && (
              <Link 
                to="/utilisateur" 
                className={`flex items-center px-4 py-3 rounded-lg mb-2 ${isActive('/utilisateur')}`}
                style={{ 
                  backgroundColor: location.pathname === '/utilisateur' ? '#272860' : 'transparent',
                  color: location.pathname === '/utilisateur' ? 'white' : '',
                  transition: 'background-color 0.3s ease'
                }}
              >
                <FaUsers className="text-xl mr-3" style={{ color: location.pathname === '/utilisateur' ? 'currentColor' : colors.users }} />
                Utilisateurs
              </Link>
            )}
            <Link 
              to="/produits" 
              className={`flex items-center px-4 py-3 rounded-lg mb-2 ${isActive('/produits')}`}
              style={{ 
                backgroundColor: location.pathname === '/produits' ? '#272860' : 'transparent',
                color: location.pathname === '/produits' ? 'white' : '',
                transition: 'background-color 0.3s ease'
              }}
            >
              <FaBox className="text    -xl mr-3" style={{ color: location.pathname === '/produits' ? 'currentColor' : colors.products }} />
              Gestion des Produits
            </Link>
            <Link 
              to="/commandes" 
              className={`flex items-center px-4 py-3 rounded-lg mb-2 ${isActive('/commandes')}`}
              style={{ 
                backgroundColor: location.pathname === '/commandes' ? '#272860' : 'transparent',
                color: location.pathname === '/commandes' ? 'white' : '',
                transition: 'background-color 0.3s ease'
              }}
            >
              <FaShoppingBasket className="text-xl mr-3" style={{ color: location.pathname === '/commandes' ? 'currentColor' : colors.orders }} />
              Gestion des Commandes
            </Link>
          </nav>
        </div>
        <div className="absolute bottom-0 w-full px-6 py-5 bg-gray-50">
          <button 
            onClick={onLogout} 
            className="w-full text-left flex items-center text-gray-800 hover:bg-gray-200 transition-colors duration-200 rounded-lg p-3"
          >
            <FaSignOutAlt className="text-xl mr-3" /> 
            Déconnexion
          </button>
        </div>
      </div>
    </>
  );
};

export default Sidebar; 
import React, { useState, memo, useMemo, useEffect } from 'react';
import { motion } from 'framer-motion';
import { FaEye, FaSpinner, FaEyeSlash, FaList, FaMagnifyingGlass } from 'react-icons/fa6';
import { Product } from '../types/product';
import Pagination from './Pagination';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { highlightText } from '../utils/highlightText';
import CustomSelect, { SelectOption } from './common/CustomSelect';
import { cloneDeep } from 'lodash';

interface ProductTableProps {
  products: Product[];
  currentPage: number;
  itemsPerPage: number;
  totalItems: number;
  isLoading: boolean;
  onPageChange: (page: number) => void;
  onItemsPerPageChange: (itemsPerPage: number) => void;
  searchTerm: string;
  onSearch: (term: string) => void;
  onGroupFilter?: (group: string) => void;
  onTypeFilter?: (type: string) => void;
  availableGroups: {value: string, label: string}[];
  availableTypes: {value: string, label: string}[];
}

const ProductTable: React.FC<ProductTableProps> = memo(({
  products,
  currentPage,
  itemsPerPage,
  totalItems,
  isLoading,
  onPageChange,
  onItemsPerPageChange,
  searchTerm,
  onSearch,
  onGroupFilter,
  onTypeFilter,
  availableGroups,
  availableTypes
}) => {
  const [selectedGroup, setSelectedGroup] = useState<string>('');
  const [selectedType, setSelectedType] = useState<string>('');

  const navigate = useNavigate();

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { staggerChildren: 0.1 } }
  };

  const rowVariants = {
    hidden: { opacity: 0, x: -40 },
    visible: { 
      opacity: 1, 
      x: 0,
      transition: {
        duration: 0.2
      }
    }
  };

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const getStatusLabel = (status: number): string => {
    switch (status) {
      case 1: return 'Actif';
      case 2: return 'Inactif';
      default: return 'Inconnu';
    }
  };

  const getCustomAttributeValueFromKey = (product: Product, key: string): string => {
    const customAttribute = product.customAttributes.find(attr => attr.attribute_code === key);
    if (!customAttribute) return 'N/A';

    if (key === 'universal_product_group') {
      const group = availableGroups.find(g => g.value === customAttribute.value);
      return group ? group.label : customAttribute.value;
    }

    if (key === 'universal_product_type') {
      const type = availableTypes.find(t => t.value === customAttribute.value);
      return type ? type.label : customAttribute.value;
    }

    return customAttribute.value;
  };

  const handleViewDetails = (product: Product) => {
    navigate(`/produits/${product.id}`);
  };

  // Gestionnaires de changement pour les filtres
  const handleGroupChange = (value: string) => {
    setSelectedGroup(value);
    onGroupFilter?.(value);
  };

  const handleTypeChange = (value: string) => {
    setSelectedType(value);
    onTypeFilter?.(value);
  };

  let groupOptions: SelectOption[] = [
    { value: '', label: 'Tous les groupes' },
    ...availableGroups.sort((a, b) => a.label.localeCompare(b.label))
  ];

  let typeOptions: SelectOption[] = [
    { value: '', label: 'Tous les types' },
    ...availableTypes.sort((a, b) => a.label.localeCompare(b.label))
  ];
  console.log('groupOptions', cloneDeep(groupOptions));
  console.log('typeOptions', cloneDeep(typeOptions));

  const itemsPerPageOptions: SelectOption[] = [
    { value: '20', label: '20 par page' },
    { value: '50', label: '50 par page' },
    { value: '100', label: '100 par page' },
    { value: '200', label: '200 par page' }
  ];

  // Ajout d'une animation pour le loader
  const loaderVariants = {
    animate: {
      rotate: 360,
      transition: {
        duration: 1,
        repeat: Infinity,
        ease: "linear"
      }
    }
  };

  // Populates groupOptions & typeOptions
  useEffect(() => {
    groupOptions = [
      { value: '', label: 'Tous les groupes' },
      ...availableGroups.sort((a, b) => a.label.localeCompare(b.label))
    ];
    typeOptions = [
      { value: '', label: 'Tous les types' },
      ...availableTypes.sort((a, b) => a.label.localeCompare(b.label))
    ];
  }, [availableGroups, availableTypes]);

  return (
    <div className="container mx-auto p-4 z-10 relative flex flex-col h-full">
      <div className="mb-4 flex space-x-2">
        <input
          type="text"
          placeholder="Rechercher (sku, uuid ou asin)..."
          value={searchTerm}
          onChange={(e) => onSearch(e.target.value)}
          className="flex-grow appearance-none border rounded p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
        <div className="w-[200px]">
          <CustomSelect
            options={groupOptions}
            value={selectedGroup ? groupOptions.find(option => option.value === selectedGroup) : groupOptions[0]}
            onChange={(value) => handleGroupChange(value)}
            placeholder="Tous les groupes"
            key={`group-select-${availableGroups.length}`}
          />
        </div>
        <div className="w-[200px]">
          <CustomSelect
            options={typeOptions}
            value={selectedType ? typeOptions.find(option => option.value === selectedType) : typeOptions[0]}
            onChange={handleTypeChange}
            placeholder="Tous les types"
            key={`type-select-${availableTypes.length}`}
          />
        </div>
        <div className="w-[150px]">
          <CustomSelect
            options={itemsPerPageOptions}
            value={itemsPerPageOptions.find(option => option.value === String(itemsPerPage)) || itemsPerPageOptions[0]}
            onChange={(value) => onItemsPerPageChange(Number(value))}
            placeholder="Items par page"
            isClearable={false}
          />
        </div>
        <button
          onClick={() => onPageChange(1)}
          className="bg-[#272860] hover:bg-[#1e1e50] text-white font-bold py-2 px-4 rounded flex items-center"
        >
          <FaMagnifyingGlass className="inline mr-2" />Rechercher
        </button>
      </div>
      
      <div className="mb-4 text-gray-600">
        {totalItems} résultat{totalItems > 1 ? 's' : ''} trouvé{totalItems > 1 ? 's' : ''}
      </div>

      {isLoading ? (
        <div className="flex-grow flex flex-col justify-center items-center">
          <motion.div
            variants={loaderVariants}
            animate="animate"
            className="mb-4"
          >
            <FaSpinner className="h-12 w-12 text-[#272860]" />
          </motion.div>
          <p className="text-gray-600 text-lg">Chargement des produits...</p>
        </div>
      ) : products.length > 0 ? (
        <motion.div 
          className="flex flex-col h-[calc(100vh-250px)]"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="overflow-auto shadow-md rounded-lg flex-grow">
            <table className="min-w-full divide-y divide-gray-200 text-sm">
              <thead className="bg-[#272860] text-white sticky top-0 z-10">
                <tr>
                  <th className="px-2 py-2 text-left text-sm font-medium uppercase tracking-wider w-[8%]">SKU</th>
                  <th className="px-2 py-2 text-left text-sm font-medium uppercase tracking-wider w-[50%] min-w-[170px] overflow-hidden">Nom</th>
                  <th className="px-2 py-2 text-left text-sm font-medium uppercase tracking-wider w-[10%]">UUID</th>
                  <th className="px-2 py-2 text-left text-sm font-medium uppercase tracking-wider w-[12%]">Groupe</th>
                  <th className="px-2 py-2 text-left text-sm font-medium uppercase tracking-wider w-[12%]">Type</th>
                  <th className="px-2 py-2 text-left text-sm font-medium uppercase tracking-wider w-[8%]">ASIN</th>
                  <th className="px-2 py-2 text-left text-sm font-medium uppercase tracking-wider w-[7%]">Prix</th>
                  <th className="px-2 py-2 text-left text-sm font-medium uppercase tracking-wider w-[7%]">Type</th>
                  <th className="px-2 py-2 text-left text-sm font-medium uppercase tracking-wider w-[7%]">Statut</th>
                  <th className="px-2 py-2 text-left text-sm font-medium uppercase tracking-wider w-[7%]">Canal</th>
                  <th className="px-2 py-2 text-center text-sm font-medium uppercase tracking-wider">Actions</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {products.map((product, index) => (
                  <motion.tr 
                    key={product['@id']} 
                    variants={rowVariants}
                    initial="hidden"
                    animate="visible"
                    className={`
                      ${index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}
                      hover:bg-gray-100 transition-colors duration-150 ease-in-out
                    `}
                  >
                    <td className="px-2 py-2 whitespace-nowrap text-gray-500">
                      <div className="truncate" data-tooltip-id="cell-tooltip" data-tooltip-content={product.sku}>
                        {highlightText(product.sku || '', searchTerm)}
                      </div>
                    </td>
                    <td className="px-2 py-2 whitespace-nowrap font-medium text-gray-900 max-w-0 overflow-hidden">
                      <div className="truncate" data-tooltip-id="cell-tooltip" data-tooltip-content={product.name}>
                        {highlightText(product.name || '', searchTerm)}
                      </div>
                    </td>
                    <td className="px-2 py-2 whitespace-nowrap text-gray-500">
                      {highlightText(getCustomAttributeValueFromKey(product, 'universal_unique_product_id') || '', searchTerm)}
                    </td>
                    <td className="px-2 py-2 whitespace-nowrap text-gray-500">
                      {getCustomAttributeValueFromKey(product, 'universal_product_group') || 'N/A'}
                    </td>
                    <td className="px-2 py-2 whitespace-nowrap text-gray-500">
                      {getCustomAttributeValueFromKey(product, 'universal_product_type') || 'N/A'}
                    </td>
                    <td className="px-2 py-2 whitespace-nowrap text-gray-500">
                      {highlightText(getCustomAttributeValueFromKey(product, 'asin') || '', searchTerm)}
                    </td>
                    <td className="px-2 py-2 whitespace-nowrap text-gray-500">{product.price.toFixed(2)} €</td>
                    <td className="px-2 py-2 whitespace-nowrap text-gray-500">{product.type_id}</td>
                    <td className="px-2 py-2 whitespace-nowrap">
                      <span className={`px-1.5 py-0.5 inline-flex text-xs leading-5 font-semibold rounded-full ${
                        product.status === 1 ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                      }`}>
                        {getStatusLabel(product.status)}
                      </span>
                    </td>
                    <td className="px-2 py-2 whitespace-nowrap text-gray-500">
                      <div className="truncate" data-tooltip-id="cell-tooltip" 
                           data-tooltip-content={product.channel?.name || 'N/A'}>
                        {highlightText(product.channel?.name || '', searchTerm)}
                      </div>
                    </td>
                    <td className="px-2 py-2 whitespace-nowrap text-center">
                      <FaEye
                        className="inline-block cursor-pointer hover:scale-110 transform transition duration-150"
                        style={{ 
                          backgroundColor: '#272860', 
                          padding: '6px', 
                          borderRadius: '50%', 
                          color: 'white',
                          width: '24px', 
                          height: '24px'
                        }}
                        onClick={() => handleViewDetails(product)}
                      />
                    </td>
                  </motion.tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="mt-4 flex justify-between items-center">
            <div className="text-gray-600">
              {totalItems} résultat{totalItems > 1 ? 's' : ''} trouvé{totalItems > 1 ? 's' : ''}
            </div>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={onPageChange}
            />
          </div>
        </motion.div>
      ) : (
        <div className="flex-grow flex flex-col justify-center items-center">
          <p className="text-gray-600 text-lg">Aucun produit trouvé</p>
        </div>
      )}
      
      <Tooltip
        id="visibility-tooltip"
        place="top"
        className="z-50"
        style={{
          backgroundColor: '#1F2937',
          color: 'white',
          padding: '4px 8px',
          borderRadius: '4px',
          fontSize: '0.875rem'
        }}
      />
      <Tooltip
        id="cell-tooltip"
        place="top"
        className="z-50"
        style={{
          backgroundColor: '#1F2937',
          color: 'white',
          padding: '4px 8px',
          borderRadius: '4px',
          fontSize: '0.875rem',
          maxWidth: '300px'
        }}
      />
    </div>
  );
});

export default ProductTable; 
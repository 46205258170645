import API_URL from './config';
import { AuthResponse } from '../types/user';
import { fetchWithAuth } from './utils';

export async function authenticate(username: string, password: string): Promise<AuthResponse> {
    try {
        const response = await fetchWithAuth(`${API_URL}/login_check`, {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ username, password })
        });

        if (!response.ok) {
            const errorText = await response.text();
            console.error('Erreur réponse API:', errorText);
            throw new Error('Échec de la connexion');
        }

        const data: AuthResponse = await response.json();
        localStorage.setItem('user', JSON.stringify(data)); 
        return data;
    } catch (error) {
        console.error('Erreur lors de la tentative de connexion:', error);
        throw error;
    }
}

export function logoutUser(): void {
    localStorage.removeItem('token');
} 
import React from 'react';
import Login from '../components/Login';
import { AuthResponse } from '../types/user';

interface LoginPageProps {
  onLoginSuccess: (data: AuthResponse) => void;
}

const LoginPage: React.FC<LoginPageProps> = ({ onLoginSuccess }) => {
  return (
    <div className="min-h-screen bg-gray-50">
      <Login onLoginSuccess={onLoginSuccess} />
    </div>
  );
};

export default LoginPage; 
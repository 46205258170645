import { useState, ChangeEvent } from 'react';

type FieldValue = string | boolean | string[] | number;

export function useForm<T>(initialValues: T) {
    const [values, setValues] = useState<T>(initialValues);
    const [errors, setErrors] = useState<Partial<Record<keyof T, string>>>({});

    const handleChange = (
        e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
    ) => {
        const { name, value, type } = e.target;
        
        setValues(prev => ({
            ...prev,
            [name]: type === 'checkbox' 
                ? (e.target as HTMLInputElement).checked 
                : value
        }));
    };

    const handleArrayChange = (name: keyof T, value: string, checked: boolean) => {
        setValues(prev => ({
            ...prev,
            [name]: checked
                ? [...((prev[name] as string[]) || []), value]
                : ((prev[name] as string[]) || []).filter(item => item !== value)
        }));
    };

    const reset = () => setValues(initialValues);

    return {
        values,
        setValues,
        errors,
        setErrors,
        handleChange,
        handleArrayChange,
        reset
    };
} 
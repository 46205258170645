/**
 * Interface for objects containing an @id
 */
interface WithId {
    '@id': string;
    [key: string]: any; // allows other properties
}

class IdTransformer {
    /**
     * Transforms an object containing an @id formatted as '/api/resource/{id}' into a number
     * @param {WithId} object - The object containing the @id field
     * @returns {number|null} - The numeric ID or null if invalid
     */
    static getNumericId(object: WithId | null | undefined): number | null {
        if (!object || !object['@id'] || typeof object['@id'] !== 'string') {
            return null;
        }

        // Extract the last segment of the URL which should be the ID
        const segments = object['@id'].split('/');
        const lastSegment = segments[segments.length - 1];

        // Convert to number
        const numericId = parseInt(lastSegment, 10);

        // Check if conversion is valid
        return isNaN(numericId) ? null : numericId;
    }

    static addIdInMemberList(member: WithId[]): WithId[] {
        return member.map((item) => ({
            ...item,
            id: IdTransformer.getNumericId(item),
        }));
    }
}

export default IdTransformer;
export type { WithId }; 